import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button, Box } from "@mui/material";
import { motion } from "framer-motion";
// import { useLanguage } from "../LanguageContext/LanguageContext";
// import { useTranslation } from "react-i18next";

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: `url('/first_post-min.png')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  color: theme.palette.common.white,
}));

const TextContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const Heading = styled(Typography)({
  fontSize: "3rem",
  marginBottom: "1rem",
  fontWeight: "bold",
  color: "#FFFFFF",
  textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
});

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  marginBottom: "2rem",
  color: "#FFFFFF",
  textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem", // Adjust font size on mobile
  },
}));

const LearnMoreButton = styled(Button)({
  padding: "1rem 2rem",
  backgroundColor: "#00bfa6",
  color: "white",
  fontSize: "1rem",
  "&:hover": {
    backgroundColor: "#009688",
  },
});

const MotionHeading = motion(Heading);
const MotionSubHeading = motion(SubHeading);

interface HeroProps {
  scrollToRef: React.RefObject<HTMLElement>; // Use the appropriate type for your ref
}
const Hero: React.FC<HeroProps> = ({ scrollToRef }) => {
  const handleClick = () => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Animation variants
  const headingVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 2.5, type: "spring" },
    },
  };

  const subHeadingVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 2, type: "spring", delay: 0.5 },
    },
  };

  // const Logo = styled("img")({
  //   position: "absolute",
  //   top: -5, // Adjust as needed
  //   left: 0, // Adjust as needed
  //   width: "200px", // Adjust size as needed
  //   height: "auto",
  // });

  // // const { setLanguage } = useLanguage();

  // const LanguageSwitcherBox = {
  //   position: "absolute",
  //   top: 0,
  //   right: 0,
  //   p: 2,
  //   display: "flex",
  //   gap: "8px", // This adds space between the buttons
  // };

  // const LanguageButton = {
  //   backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white
  //   color: "#000", // Black text color
  //   "&:hover": {
  //     backgroundColor: "rgba(255, 255, 255, 0.9)", // Slightly more opaque on hover
  //   },
  //   padding: "6px 12px", // Padding inside the button
  //   border: "1px solid rgba(0, 0, 0, 0.12)", // Optional border for better definition
  // };

  // const handleLanguageChange = (lang: string) => {
  //   // Implement your language change logic here
  //   console.log(`Language changed to ${lang}`);
  //   setLanguage(lang);
  // };

  // const { t, i18n } = useTranslation();

  // const changeLanguage = (language: string) => {
  //   i18n
  //     .changeLanguage(language)
  //     .then(() => {
  //       console.log(`Language changed to: ${language}`);
  //       console.log(<p>{t("description")}</p>);
  //     })
  //     .catch((error) => {
  //       console.error("Error changing the language", error);
  //     });
  // };

  return (
    <HeroSection>
      <TextContainer>
        <MotionHeading variants={headingVariants} initial="hidden" animate="visible">
        Professional Web Development
        </MotionHeading>
        <MotionSubHeading variants={subHeadingVariants} initial="hidden" animate="visible">
        Discover Our Services
        </MotionSubHeading>
        <LearnMoreButton onClick={handleClick}>Learn more</LearnMoreButton>
      </TextContainer>
    </HeroSection>
  );
};

export default Hero;
