import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: grey[200], // This is a light grey color, adjust as needed
        borderTop: 1, // This creates a border top of 1px
        borderColor: 'divider', // Use the theme's divider color
        py: 2, // Add padding
        mt: 'auto' // If you are using a flex layout, this pushes the footer to the bottom
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          ProTechCodes.com is operated by the company Mladí programátori s.r.o., Trieda Andreja Hlinku 606/39, 949 01, Nitra, Slovakia. Business ID 55655203, Tax ID 2322051602, no VAT ID 
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
