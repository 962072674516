import React, { useRef } from "react";
import "./styles/App.css";
import Hero from "./components/Hero/Hero";
import ServicesSection from "./components/ServicesSection/ServicesSection"; 
import Footer from "./components/Footer/Footer";
import TechnologiesSection from "./components/TechnologiesSection/TechnologiesSection";
import ContactUs from "./components/ContactUs/ContactUs";

const App: React.FC = () => {
    const servicesRef = useRef(null);

  return (
    <div className="App">
      <Hero scrollToRef={servicesRef} />
      <ServicesSection ref={servicesRef} />
      <TechnologiesSection />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default App;
