import React, { useState } from "react";
import { Typography, Grid, Paper, Container, useTheme, CircularProgress } from "@mui/material";
// import { styled } from '@mui/material/styles';
import IconReact from "../Icons/IconReact";
import IconVuejs from "../Icons/IconVuejs";
import IconNextjs from "../Icons/IconNextjs";
import IconJavascript from "../Icons/IconJavascript";
import IconTypescript from "../Icons/IconTypescript";
import IconBxlNodejs from "../Icons/IconBxlNodejs";
import IconPython from "../Icons/IconPython";
import IconDjango from "../Icons/IconDjango";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionPaper = motion(Paper);

const getCardVariants = (index: number) => ({
  offscreen: {
    x: index % 2 === 0 ? -200 : 200, // Alternate based on whether index is even or odd
    opacity: 0,
    rotate: -10, // Start with a slight rotation
  },
  onscreen: {
    x: 0,
    opacity: 1,
    rotate: 0, // End rotation at 0 degrees
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.2, // Make the duration a bit longer
      delay: index * 0.1, // Delay each card slightly more than the previous one
    },
  },
});

const TechnologiesSection: React.FC = () => {
  // Define your technologies and tools here
  const developmentTechnologies = [
    { name: "React", IconComponent: IconReact },
    { name: "Vue.js", IconComponent: IconVuejs },
    { name: "Next.js", IconComponent: IconNextjs },
    { name: "JavaScript", IconComponent: IconJavascript },
    { name: "TypeScript", IconComponent: IconTypescript },
    { name: "Node.js", IconComponent: IconBxlNodejs },
    { name: "Python", IconComponent: IconPython },
    { name: "Django", IconComponent: IconDjango },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Trigger as soon as 10% of the element is visible
  });

  const [bgLoaded, setBgLoaded] = useState(false);
  const theme = useTheme();

  const handleBgLoad = () => {
    setBgLoaded(true);
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: theme.palette.grey[200], // This will show before the image loads
      }}
    >
      {/* Background image */}
      <img
        src={`${process.env.PUBLIC_URL}/backg.jpeg`}
        alt="Background"
        style={{
          width: "100%",
          position: "absolute", // Make the image absolute to allow content over it
          left: 0,
          top: 0,
          zIndex: -1, // Put the image behind the content
          display: bgLoaded ? "block" : "none",
        }}
        onLoad={handleBgLoad}
      />

      {/* Content */}
      <br />
      <Container style={{ position: "relative", zIndex: 2 }}>
        <Typography variant="h4" gutterBottom>
          Tools and Technology
        </Typography>
        <Typography paragraph>
          Our approach blends established methodologies and cutting-edge technologies. We continuously experiment with
          the latest in technical and design innovation, incorporating only the most effective strategies into our
          workflow to enhance productivity and deliver superior results.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Development technologies
        </Typography>
        <Grid container spacing={2} ref={ref}>
          {developmentTechnologies.map((tech, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <MotionPaper
                variants={getCardVariants(index)}
                initial="offscreen"
                animate={inView ? "onscreen" : "offscreen"}
                whileHover={{ scale: 1.05 }}
                style={{
                  padding: theme.spacing(2),
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                  background: "none",
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <tech.IconComponent style={{ fontSize: "64px" }} />
                <Typography variant="subtitle1">{tech.name}</Typography>
              </MotionPaper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Loading indicator */}
      <br />
      {!bgLoaded && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1, // Make sure the loader is above the image but below the content
          }}
        />
      )}
    </div>
  );
};

export default TechnologiesSection;
