import React from "react";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useInView } from "react-intersection-observer";
import Typography from "@mui/material/Typography";
import { DiReact, DiNodejsSmall, DiHtml5, DiCss3, DiJavascript1, DiBootstrap } from "react-icons/di";
import Box from "@mui/material/Box";

const SectionHeader = styled(Typography)`
  font-size: 3rem;
  color: #30475e;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.5rem;
`;

const HeaderDescription = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem", // Default font size for larger screens
  color: "#333",
  maxWidth: "800px",
  margin: "0 auto 4rem",
  lineHeight: 1.6,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem", // smaller size on mobiles
  },
}));

// const ServicesContainer = styled.section`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around; // This will space your items evenly
//   padding: 4rem;
//   text-align: center;
// `;

const ServiceCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Stack children vertically
  justifyContent: "space-between", // Distribute space for heading and description
  alignItems: "flex-start", // Align items to the start of the flex container
  padding: theme.spacing(2),
  textAlign: "left",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  margin: theme.spacing(1),
  border: "none", // Add this line to remove borders from the Paper component
  overflow: "hidden", // Ensures content doesn't overflow the card
  height: "100%", // Take full height of the parent
  boxSizing: "border-box", // Include padding and border in the element's total width and height
  [theme.breakpoints.down("md")]: {
    // Adjustments for medium-sized screens and below
    padding: theme.spacing(1),
    "& > *": {
      fontSize: "0.9rem", // Adjust font size for text inside the card
    },
  },
  [theme.breakpoints.down("sm")]: {
    // Adjustments for small screens
    padding: theme.spacing(0.5),
    "& > *": {
      fontSize: "0.8rem", // Further reduce font size for smaller screens
    },
  },
}));

const GridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2),
  },
  "& > div": {
    height: "100%",
  },
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    item: {
      xs: 12, // Full width on smaller screens
    },
  },
}));

// const Icon = styled("img")`
//   width: 300px;
//   height: 300px;
//   margin-bottom: 1rem;
// `;

const Title = styled(Typography)(({ theme }) => ({
  color: "#333",
  marginBottom: "1rem",
  fontWeight: "bold", // Keep the title bold
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem", // Adjust title font size on mobiles
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: "#666",
  // ... other styles ...
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem", // Smaller font size for mobiles
    overflowWrap: "break-word", // Break long words onto the next line
    textAlign: "justify", // Justify text on mobile devices
    paddingRight: theme.spacing(2), // Add right padding on mobile devices
    // or use marginRight instead, depending on your layout
  },
}));

// // Animation variants for framer-motion
// const cardVariants = {
//   hidden: { opacity: 0, y: 50 },
//   visible: { opacity: 1, y: 0 },
// };

interface Service {
  title: string;
  description: string;
  icon: JSX.Element; // Use JSX.Element for React components
}

const servicesData: Service[] = [
  {
    title: "Websites",
    description:
      "Crafting state-of-the-art websites tailored to your brand, our comprehensive solutions include design, content, and seamless functionality, ensuring a robust online presence.",
    icon: <DiHtml5 size="300" />,
  },
  {
    title: "Backend Development",
    description:
      "Our backend solutions are the powerhouse of your digital platforms, offering scalable, efficient, and secure server-side applications that propel your business forward.",
    icon: <DiNodejsSmall size="300" />,
  },
  {
    title: "Frontend Development",
    description:
      "Creating intuitive and engaging user interfaces, we ensure your customers enjoy a smooth and responsive experience across all devices and platforms.",
    icon: <DiReact size="300" />,
  },
  {
    title: "Mobile Applications",
    description:
      "From concept to launch, we specialize in developing mobile apps that deliver performance and user engagement, driving growth and customer loyalty.",
    icon: <DiJavascript1 size="300" />,
  },
  {
    title: "Web 3.0 Development",
    description:
      "We're at the forefront of the Web 3.0 revolution, building decentralized applications that offer users greater control and security over their digital interactions.",
    icon: <DiBootstrap size="300" />,
  },
  {
    title: "UX/UI Design",
    description:
      "We meld aesthetics with functionality to design digital experiences that are not only visually compelling but also user-centric and conversion-focused.",
    icon: <DiCss3 size="300" />,
  },
];


const leftVariants = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0 },
};

const rightVariants = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0 },
};

// Component
const ServicesSection = React.forwardRef<HTMLDivElement>((props, ref) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust as needed
  });

  return (
    <div ref={ref} {...props}>
      <SectionHeader>Our Expertise</SectionHeader>
      <HeaderDescription variant="body1" align="center">
        Guiding you through the journey of digital transformation, we specialize in creating cutting-edge web solutions
        tailored to your unique business needs.
      </HeaderDescription>
      <Grid container spacing={4} justifyContent="center">
        {servicesData.map((service: Service, index: number) => (
          <GridItem item xs={12} sm={6} key={service.title}>
            <motion.div
              ref={index === 0 ? inViewRef : null} // Apply ref to only the first element
              variants={index % 2 === 0 ? leftVariants : rightVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"} // Control animation based on inView
              transition={{
                delay: 0.1 * index,
                duration: 2,
              }}
            >
              <ServiceCard elevation={3}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  {/* Use Box with sx prop for flex layout */}
                  {service.icon} {/* Directly place the JSX.Element */}
                  <Box>
                    <Title>{service.title}</Title>
                    <Description>{service.description}</Description>
                  </Box>
                </Box>
              </ServiceCard>
            </motion.div>
          </GridItem>
        ))}
      </Grid>
      <br />
      <br />
    </div>
  );
});

export default ServicesSection;
