import React from "react";
import { Typography, Button, Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionBox = motion(Box);

const ContactUs: React.FC = () => {
  const theme = useTheme();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  // Animation variants for the container
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  // Animation variants for children elements
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:protechcodes@gmail.com";
  };

  return (
    <MotionBox
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      style={{
        textAlign: "center",
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <motion.div variants={childVariants}>
        <Typography variant="h4" gutterBottom>
          Contact us
        </Typography>
      </motion.div>
      <motion.div variants={childVariants}>
        <Typography>We're here to help with any inquiries you may have. Reach out to us at:</Typography>
      </motion.div>
      <motion.div variants={childVariants}>
        <Button
          variant="contained"
          onClick={handleEmailClick}
          style={{
            marginTop: theme.spacing(2),
            padding: theme.spacing(1, 4),
            fontSize: theme.typography.button.fontSize,
            boxShadow: theme.shadows[2],
          }}
        >
          protechcodes@gmail.com
        </Button>
      </motion.div>
    </MotionBox>
  );
};

export default ContactUs;
